window.addEventListener('DOMContentLoaded', (event) => {
  const openIcon = document.querySelector('#burger-menu-open-icon');
  const closeIcon = document.querySelector('#burger-menu-close-icon');
  const mobileNav = document.querySelector('.hidden-mobile-nav');
  openIcon.onclick = () => {
    openIcon.className = 'hidden';
    mobileNav.className = 'visible-mobile-nav';
    closeIcon.className = 'mobile';
  }; 
  closeIcon.onclick = () => {
    closeIcon.className = 'hidden';
    openIcon.className = 'mobile';
    mobileNav.className = 'hidden-mobile-nav';
  };
});