import React, { useEffect, useState } from 'react'

const PostDetails = ({ wordCount, speedLabel }) => {
  const readingSpeed = Math.ceil(wordCount / 200)

  const [headers, setHeaders] = useState([])

  useEffect(() => {
    const articleHeaders = []
    document.querySelectorAll('h1').forEach(element => {
      articleHeaders.push({
        offsetTop: element.offsetTop,
        innerText: element.innerText,
      })
    })
    setHeaders(articleHeaders)
  }, [wordCount])

  return (
    <div>
      <p>
        <strong>
          {speedLabel}: {readingSpeed}m
        </strong>
      </p>
      <ul>{headers.map(HeaderLink)}</ul>
    </div>
  )
}

const HeaderLink = ({ innerText, offsetTop }, index) => {
  const gotoHeader = () => {
    window.scrollTo(0, offsetTop - 80)
  }
  return (
    <li key={index} onClick={gotoHeader} className="header-link">
      {innerText}
    </li>
  )
}

export default PostDetails
